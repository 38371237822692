import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Flex, notification, Upload } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import Loading from "../../components/Loading/LoadingEffect";
import { deleteNotifications } from "../../controllers/notifications/notificationsControllers";
import moment from "moment";
import { InfoCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

const NotificationAddEditPopUp = ({
  NotificationsPopUpmodal,
  notificationToggleViewPopUp,
  viewData,
  setAddEditData
}) => {
  const handleCancel = () => {
    setAddEditData("");
    notificationToggleViewPopUp();
  };
  
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const form = new FormData();

    try {
      const options = {
        url: `notifications/delete-notifications`,
        postData: {
          id : viewData._id,
      }
    }
    const res = await deleteNotifications(options);
    if(res.status === true){
      notification.open({
        message: "User Notifiactions deleted successfully.",
        description:  `Notifiactions delete successfully.`,
        placement: "topRight", 
        icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        duration:2
      });
      // setDeleteId("");
      notificationToggleViewPopUp();
    } else{
      notification.open({
        message: "Opps!",
        description:  `${res?.message}`,
        placement: "topRight", 
        icon: <InfoCircleOutlined style={{ color: 'red' }} />,
        duration:2
      });
    }
    }catch(error){
      console.log('error',error);
      notification.open({
        message: "Opps!",
        description:  `Operation not perform yet! please try in some time.`,
        placement: "topRight", 
        icon: <InfoCircleOutlined style={{ color: 'red' }} />,
        duration:2
      });
    }
  };

  /************************************ */
  const [errors, setErrors]= useState("");
  const [loading, setLoading] = useState(false);

    return (
    <Dialog
      open={NotificationsPopUpmodal}
      onClose={notificationToggleViewPopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              View Notifications
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form" onSubmit={handleSubmit}>
                  <input type="hidden" name="id" id="id" value={viewData._id}/>
                  <div className="form-group row">

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label for="title" className="categories_open_popup_label_name" >
                      <span style={{color:"red"}}>*</span>Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        readOnly={true}
                        value={viewData?.title}
                        
                        class="form-control categories_select_tg_enter_product"
                        placeholder="Enter title"
                      />
                      {errors?.title?(<p className="text-danger">{errors.title}</p>):""}
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label for="short_description" className="categories_open_popup_label_name" >
                      <span style={{color:"red"}}>*</span>Message
                      </label>
                      <textarea 
                        name="message" 
                        id="message"
                        class="form-control categories_select_tg_enter_product"
                        placeholder="Enter message."
                        readOnly={true}
                      >
                        {viewData?.message}
                      </textarea>
                      {errors?.message?(<p className="text-danger">{errors.message}</p>):""}
                    </div>

                    <div>
                    </div>
                    
                    <div className="inventory_open_popup_action_btn">
                      <button
                        type="button"
                        class="btn btn-light categories_cancel_button"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="Submit"
                        class="btn btn-danger "
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
        </div>
        <Loading
          isLoading={loading}
        />
      </div>
    </Dialog>
  );
};

export default NotificationAddEditPopUp;
