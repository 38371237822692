import React from "react";
import { IoNotificationsCircleSharp } from "react-icons/io5";
import "../AllCSSFile.css";

function CommonComponent() {
  return (
    <div>
      <nav class="navbar navbar-light  justify-content-between w-100 navbar_content">
        <a class="navbar-brand common_inventory_heading">Inventory</a>
        <form class="form-inline common_component_form">
          <input
            class="form-control mr-sm-2 common_component_input_search"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <IoNotificationsCircleSharp  className="common_file_notification"/>
        </form>
      </nav>
    </div>
  );
}

export default CommonComponent;
