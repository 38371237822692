import { API_STORE_URL } from "../../config/constants";
import { postRequest,fetchIpAddress } from "../API";

/*********************************************************
* Function Name : List
* Description   : Get list of all bookings
* By            : Afsar Ali
* Date          : 21-05-2024 
*********************************************************/
export const list = async (options) =>{
    try {
        const params = {
            url : `${API_STORE_URL}bookings/day-care/list`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : chnageStatus
* Description   : Chnage booking status
* By            : Afsar Ali
* Date          : 21-05-2024 
*********************************************************/
export const chnageStatus = async (options) =>{
    try {
        const params = {
            url : `${API_STORE_URL}bookings/day-care/change-status`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function
/*********************************************************
* Function Name : cancelBooking
* Description   : cancel booking status
* By            : Afsar Ali
* Date          : 21-05-2024 
*********************************************************/
export const cancelBooking = async (options) =>{
    try {
        const params = {
            url : `${API_STORE_URL}bookings/day-care/decline-booking`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : availableSlots
* Description   : cancel booking status
* By            : Afsar Ali
* Date          : 21-05-2024 
*********************************************************/
export const availableSlots = async (options) =>{
    try {
        const params = {
            url : `${API_STORE_URL}bookings/day-care/available-slots`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : rescheduleBooking
* Description   : reschedule Booking appointment
* By            : Afsar Ali
* Date          : 21-05-2024 
*********************************************************/
export const rescheduleBooking = async (options) =>{
    try {
        const params = {
            url : `${API_STORE_URL}bookings/day-care/reschedule-appointment`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

