import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import Slide from "@mui/material/Slide";
import "../../BookingDetails.css";
import { notification } from "antd";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import moment from "moment";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { availableSlots, rescheduleBooking } from '../../../../controllers/bookings/veterinaryController'
import { Link, useNavigate } from "react-router-dom";
import LoadingEffect from '../../../../components/Loading/LoadingEffect';

const BookingFilterDrawer = ({ drawerOpen, RequesttoggleDrawer, bookingData }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState(new Date());
  const [bookingSlots, setBookingSlots] = useState([]);
  const [appointmentTime, setAppointmentTime] = useState("");
  const onChange = (value, event) => {
    const t1 = moment(date).format('YYYY-MM-DD');
    const t2 = moment(value).format('YYYY-MM-DD');
    if(t1 !== t2){
      setAppointmentTime("")
      const now = new Date();
      const time = `${now.getHours()}:${now.getMinutes()}`
      const tt = moment(`${t2} ${time}`).format('YYYY-MM-DD HH:mm:ss');
      setDate(new Date(tt));
    }
  };

  const getCurrentStoreTime = async () => {
    try {
      setIsLoading(true);
      // Get the today store time
      let open_time = '08:00';
      let close_time = '20:00';
      const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      const currentDay = daysOfWeek[date.getDay()];
      const currentDayObject = bookingData?.shopId?.store_time.find(item => item.day === currentDay);
      const currentOpenTime = new Date(`2000-01-01T${currentDayObject.open_time}`);
      const currentTime = date.getHours() * 60 + date.getMinutes(); 
      const openHour = currentOpenTime.getHours() * 60 + currentOpenTime.getMinutes(); 
      
      if (currentTime >= openHour) {
          const now = moment(new Date()).format('YYYY-MM-DD');
          const currentDate = moment(date).format('YYYY-MM-DD');
          if(now === currentDate){
            open_time = `${date.getHours()}:${date.getMinutes()}`;
          } else{
            open_time = '08:00';
          }
          close_time = currentDayObject.close_time;

          const options = {
            serviceId : bookingData?.category?._id,
            store_id : bookingData?.shopId?._id,
            branch_id : bookingData?.branch?._id,
            date : moment(date).format('YYYY-MM-DD'),
            openTime : open_time,
            closeTime : close_time
          }
          const res = await availableSlots(options);
          console.log('resp',res);
          if(res.status === true){
            setBookingSlots(res.result);
          }
          setIsLoading(false);
      } else {
          setIsLoading(false);
          console.log('The store is closed.', close_time);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(()=>{
    if(bookingData){
      getCurrentStoreTime();
    }
  },[date]);

  const handleTimeChnage = (item) =>{
    if(item.status === "Available"){
      setAppointmentTime(item.time);
    }
  };

  const submitHandler = async () =>{
    try {
      const options = {
        booking_id : bookingData._id,
        date : moment(date).format('YYYY-MM-DD'),
        time : appointmentTime,
      }
      const res = await rescheduleBooking(options);
      if(res.status === true){
        notification.open({
          message: "Reschedule  Successfully",
          description: "Appointment reschedule successfully .",
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
        });
        navigate('/bookings/veterinary/list');
      }else{
        notification.open({
          message: "Oops!",
          description: "Error while appointment reschedule, Please try again.",
          placement: "topRight",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      notification.open({
        message: "Error!",
        description: "Please try again after some time.",
        placement: "topRight",
        icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      });
    }
  }

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={RequesttoggleDrawer}
      className="reuest_filter_drawer_main_section"
    >
      <Slide direction="left" in={drawerOpen}>
        <div className="myDrawer drawer_for_width_re_schedule">
          <div className="myMainDrawerClass">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Re - Schedule
            </Typography>

            <div>
              <IconButton onClick={RequesttoggleDrawer}>
                <IoMdClose />
              </IconButton>
            </div>
          </div>

          <Typography variant="body1" component="div">
            <div className="re_schedule_main_section">
              <div className=" row re_schedule_main_section_child_section">
                <Calendar onChange={onChange} value={date} className="react_calander_in_sign_up"/>
                <div className="row">
                  <div className="container vendor_reschedule_card_section">
                    <div className="row re_schedule_card_all_section">
                      {bookingSlots.length > 0 ? bookingSlots.map((item)=>(<>
                        {item.time === appointmentTime?(<>
                          <div className="col-sm-4">
                            <div className="card re_schedule_child_card re_schedule_child_card_first_card">
                              <div className="card-body">
                                <Link to={false} className="time_link_vendor">
                                {moment(`2020-04-23 ${item.time}`).format('hh:mm A')}
                                </Link>
                              </div>
                              <span>{item.status}</span>
                            </div>
                          </div>
                        </>):(<>
                          <div className="col-sm-4">
                            <div className={`card re_schedule_child_card ${item.status !== 'Available'?'re_schedule_child_card_second_child':''}`} onClick={()=>handleTimeChnage(item)}>
                              <div className="card-body">
                                <Link to={false} className="time_link_vendor_tag">
                                {moment(`2020-04-23 ${item.time}`).format('hh:mm A')}
                                </Link>
                              </div>
                              <span>{item.status}</span>
                            </div>
                          </div>
                        </>)}
                      </>
                      )) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <button className="btn btn-danger" onClick={submitHandler}>Re-Schedule</button>
                </div>
              </div>
            </div>
          </Typography>
        </div>
      </Slide>
      <LoadingEffect isLoading ={isLoading} />
    </Drawer>
  );
};

export default BookingFilterDrawer;

// BY Deafult Open
