import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoNotificationsCircleSharp } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";
import "./OrdersNow.css";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { notification } from "antd";
import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";


import moment from "moment";
import { ASSETS_BASE_URL } from "../../../config/constants";
import { chnageStatus } from '../../../controllers/orders/ordersControllers';
import OrderConformatuinPopUp from "../OrderConformatuinPopUp";
import OrderDeclineConformationPopup from "../OrderDeclineConformationPopup";

const  OrdersDetails = () => {

  const renderOrderButton = (currentStatus, newStatus, buttonText, popupMessage) => {
    return orderData.status === currentStatus && (
      <button
        type="button"
        className="order_now_heading_button1"
        onClick={() => {
          setPopupMessage(popupMessage);
          setOrderStatus(newStatus);
          togglePopUp();
        }}
      >
        <FaRegCircleCheck /> {buttonText}
      </button>
    );
  };

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [orderData, setOrderData] = useState(state);

  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [declinePopUpOpen, setdeclinePopUpOpen] = useState(false);
  const toggleDeclinePopUp = () => {
    setdeclinePopUpOpen(!declinePopUpOpen);
  };

  const [order_status, setOrderStatus]=useState("");
  const [popupMessage, setPopupMessage]=useState("");

  const handleOrderStatusChange = async (status='') =>{  
    try {
      let statusError = false;
      let orderStatus = orderData.status;
      if(status === 'Processing' && orderData.status === 'Pending'){
          orderStatus = 'Processing'
      } else if(status === 'Shipped' && orderData.status === 'Processing'){
        orderStatus = 'Shipped'
      } else if(status === 'Shipped' && orderData.status === 'Processing'){
        orderStatus = 'Delivered'
      } else if(status === 'Delivered' && orderData.status === 'Shipped'){
        orderStatus = 'Delivered'
      } else if(status === 'Complete' && orderData.status === 'Delivered'){
        orderStatus = 'Complete'
      } else if(status === 'Canceled' && orderData.status !== 'Complete'){
        orderStatus = 'Canceled'
      } else {
        statusError = true
      }
      if(statusError === true){
        notification.open({
          message: `Oops!`,
          description: " Your order status not changed .",
          placement: "topRight",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          duration : 2
        });
      } else{
        const options = {
          orderId : orderData._id,
          orderStatus : orderStatus
        }
        const res = await chnageStatus(options);
        if(res.status){
          notification.open({
            message: `Order Status Chnaged`,
            description: " Data Deleted Successfully .",
            placement: "topRight",
            icon: <CheckOutlined style={{ color: "green" }} />,
            duration : 2
          });
          navigate('/orders/list');
        } else{
          notification.open({
            message: `Oops!`,
            description: " Your order status not changed .",
            placement: "topRight",
            icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
            duration : 2
          });
        }
      }
    } catch (error) {
      notification.open({
        message: `Oops!`,
        description: "Server is under maintanance.",
        placement: "topRight",
        icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
        duration : 2
      });
    }
  };

  const handleOrderDecline = async (region='') =>{  
    try {
      let statusError = false;
      let orderStatus = orderData.status;
      if(orderData.status !== 'Complete'){
        orderStatus = 'Canceled'
      } else {
        statusError = true
      }
      if(statusError === true){
        notification.open({
          message: `Oops!`,
          description: " Your order status not changed .",
          placement: "topRight",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          duration : 2
        });
      } else{
        const options = {
          orderId : orderData._id,
          orderStatus : orderStatus,
          region : region
        }
        const res = await chnageStatus(options);
        if(res.status){
          notification.open({
            message: `Order Decline!`,
            description: "Order Decline Successfully .",
            placement: "topRight",
            icon: <CheckOutlined style={{ color: "green" }} />,
            duration : 2
          });
          navigate('/orders/list');
        } else{
          notification.open({
            message: `Oops!`,
            description: "Your order not decline .",
            placement: "topRight",
            icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
            duration : 2
          });
        }
      }
    } catch (error) {
      notification.open({
        message: `Oops!`,
        description: "Server is under maintanance.",
        placement: "topRight",
        icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
        duration : 2
      });
    }
  };

  
  return (
    <div>
      <div className="container-fluid">
        <div>
          <div className="orders_main_contaniner">
            <div>
              <nav class="navbar navbar-light  justify-content-between w-100 navbar_content">
                <Link to="/orders/list" class="navbar-brand common_inventory_heading">
                  <IoArrowBack className="orders_now_IoArrow" /> Orders List{" "}
                </Link>
                <form class="form-inline common_component_form">
                  <input
                    class="form-control mr-sm-2 common_component_input_search"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <IoNotificationsCircleSharp className="common_file_notification" />
                </form>
              </nav>
            </div>
            <div className="order_now_card_tilte">
              <div class="card">
                <div class="card-body order_now_date_and_time_section">
                  <div>
                    <span className="orders_now_billing_details_heading">
                      Order Id : {orderData?.order_seq_id}
                    </span>
                    <address className="order_now_address_date_and_time">
                      <span>{moment(`${orderData?.createdAt}`).format('MMM DD, YYYY, h:m A')}</span>
                    </address>
                  </div>
                  <div className="order_now_heading_button">
                    {renderOrderButton('Pending', 'Processing', 'Accept Order', 'Do you want to accept this order')}
                    {renderOrderButton('Processing', 'Shipped', 'Order out for delivery', 'Do you want to ship this order?')}
                    {renderOrderButton('Shipped', 'Delivered', 'Order Delivered', 'Do you want to Deliver this order?')}
                    {renderOrderButton('Delivered', 'Complete', 'Order Complete', 'Do you want to complete this order?')}
                    {(orderData.status !== 'Complete' && orderData.status !== 'Canceled') && (
                    <button type="button" className=" order_now_heading_button2" 
                      onClick={()=>{
                        toggleDeclinePopUp();
                      }} >
                      <IoCloseCircleOutline /> Decline Order
                    </button>)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <div className="card mb-4">
                  <div className="card-body">
                    <div class="mb-3 d-flex justify-content-between">
                      <div>
                        <span className="orders_now_billing_details_heading">
                          Product Details
                        </span>
                      </div>
                    </div>
                    <table class="table table-borderless orders_now_table">
                      <tbody>
                        {orderData?.orderdetails?.length > 0 ?(
                          orderData?.orderdetails.map((item, index)=>(<>
                        <tr key={index}>
                          <td>
                            <div class="d-flex mb-2">
                              <div class="flex-shrink-0">
                                <img
                                  src={`${ASSETS_BASE_URL}${item?.productId?.image}`}
                                  alt=""
                                  class="img-fluid orders_now_image"
                                />
                              </div>
                              <div class="flex-lg-grow-1 ms-3">
                                <h6 class="small mb-0">
                                  <a
                                    href="#"
                                    class="text-reset order_now_himalays"
                                  >
                                    {item?.productId?.name}
                                  </a>
                                </h6>
                                <span class="small order_now_qty">Qty: {item?.qty}</span>
                                <span class="small order_now_qty">Rate: {item?.rate}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="text-end order_now_table_for_product">
                              <span>{item?.subtotal}</span>
                              {/* <span className="order_now_table_discount">
                                <del className="order_now_table_discount_del">
                                  {" "}
                                  Rs 1399
                                </del>
                              </span> */}
                            </div>
                          </td>
                        </tr>
                        </>))
                        ):(<></>)}
                      </tbody>
                    </table>
                    {/* ... (rest of the code) */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Billing Details
                    </span>
                    <table className="table table-borderless orders_now_billing_details_bottom">
                      <tbody>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Subtotal
                          </td>
                          <td class="text-end order_now_rupees">{orderData?.subTotal} </td>
                        </tr>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Discount
                          </td>
                          <td class="text-end order_now_rupees">- {orderData?.discount} </td>
                        </tr>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Extra Charges{" "}
                            <span className="orders_now_convenience_fee">
                              (Convenience Fee)
                            </span>
                          </td>
                          <td class="text-end order_now_rupees">{0.00} </td>
                        </tr>
                        {orderData?.isCouponUsed === 'Y'?(
                          <tr>
                            <td colspan="2" className="orders_now_subtotal">
                              Coupon Discount{" "}
                              <span className="orders_now_convenience_fee">
                                (New200)
                              </span>
                            </td>
                            <td class="text-end order_now_rupees"> - Rs 200 </td>
                          </tr>
                        ):(<></>)}
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Tax{" "}
                            <span className="orders_now_convenience_fee">
                              ( 5% Service Tax )
                            </span>
                          </td>
                          <td class="text-end order_now_rupees"> {parseFloat(orderData?.subTotal) * 5/100}</td>
                        </tr>
                        <tr className="order_now_billing_details_border">
                          <td colspan="2" className="orders_now_subtotal">
                            Extra Cost
                          </td>
                          <td class="text-end order_now_rupees"> Rs 0</td>
                        </tr>
                        {/* <hr /> */}
                        <tr class="fw-bold">
                          <td colspan="2" className="order_now_payable_amount">
                            Payable Amount
                          </td>
                          <td class="text-end order_now_payable_amount">
                          {orderData?.paidAmount}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      User Details
                    </span>
                    <address className="order_now_address">
                      <span>Name: {orderData?.userData?.name}</span>
                      <span>Phone No. : {orderData?.userData?.country_code} - {orderData?.userData?.phone}</span>
                      <span>Email ID. : {orderData?.userData?.email}</span>
                    </address>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Shipping Address
                    </span>
                    <address className="order_now_address">
                      <span>
                        {orderData?.deliveryAddress?.firstname}{" "}{orderData?.deliveryAddress?.lastname} ({orderData?.deliveryAddress?.phone})
                        <br />
                        {orderData?.deliveryAddress?.address}{" "}
                        <br />
                        {orderData?.deliveryAddress?.city}, 
                        {orderData?.deliveryAddress?.zipcode}
                      </span>
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderConformatuinPopUp 
        popUpOpen={popUpOpen} 
        togglePopUp={togglePopUp}
        status={order_status}
        handleOrderChnageStatus={handleOrderStatusChange}
        message = {popupMessage}
      />
      <OrderDeclineConformationPopup 
        popUpOpen={declinePopUpOpen} 
        togglePopUp={toggleDeclinePopUp}
        handleOrderDecline={handleOrderDecline}
      />
    </div>
  );
}

export default OrdersDetails;
