import React, { useEffect } from "react";
import "./App.css";
import './pages/AllCSSFile.css'
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { fetchIpAddress } from "./controllers/API"
import LoginPage from "./components/LoginPage/LoginPage.js";
import ResetPassword from "./components/LoginPage/ResetPassword.js";
import Sidebar from "./components/SideBar/Sidebar.jsx";
import Dashboard from "./pages/DashBaord/Dashboard.jsx";
import Notifications from "./pages/Notification/List.js";
import Profile from "./pages/BottomProfile/Profile.js";
// import Message from "./pages/Message/Message.js";
// import Support from "./pages/Support/Support.js";
import OrderList from "./pages/Orders/List.js";
import OrdersDetails from "./pages/Orders/OrdersDetails/OrdersDetails.js";
import StoreDetailsForm from "./pages/BottomProfile/StoreDetailsForm.js";
import Setiting from "./pages/SettingPage/Setiting.js";
// import BookingList from "./pages/Booking/Grooming/List.js";
// import BookingDetails from "./pages/Booking/BookingDetails/BookingDetails.js";
import GroomingBookingList from "./pages/Booking/Grooming/List.js";
import GroomingBookingDetails from "./pages/Booking/Grooming/BookingDetails/BookingDetails.js";
import VeterinaryBookingList from "./pages/Booking/Veterinary/List.js";
import VeterinaryBookingDetails from "./pages/Booking/Veterinary/BookingDetails/BookingDetails.js";
import TrainingBookingList from "./pages/Booking/Training/List.js";
import TrainingBookingDetails from "./pages/Booking/Training/BookingDetails/BookingDetails.js";
import BoardingBookingList from "./pages/Booking/Boarding/List.js";
import BoardingBookingDetails from "./pages/Booking/Boarding/BookingDetails/BookingDetails.js";
import DayCareBookingList from "./pages/Booking/DayCare/List.js";
import DayCareBookingDetails from "./pages/Booking/DayCare/BookingDetails/BookingDetails.js";
import SignUp from "./components/SignUp/SignUp.js";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getIp = async () => {
    await fetchIpAddress();
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      await getIp();
      if (!["/", "/login", "/reset-password","/sign-up"].includes(location?.pathname)) {
        if (!sessionStorage.getItem('TOKEN')) {
          navigate('/');
        }
      }
    };

    checkAuthentication();
  }, [location, navigate]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignUp/>} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route path="/dashboard" element={ <Sidebar> <Dashboard /> </Sidebar> } />
        
        <Route path="/orders/list" element={ <Sidebar> <OrderList /> </Sidebar> } />
        <Route path="/order/details" element={ <Sidebar> <OrdersDetails /> </Sidebar> } />

        <Route path="/profile" element={<Sidebar><Profile /></Sidebar>} />

        {/* <Route path="/support" element={<Sidebar><Support /></Sidebar>} /> */}
        
        <Route path="/storeDetailsForm" element={<Sidebar><StoreDetailsForm /></Sidebar>} />
        <Route path="/notifications" element={<Sidebar><Notifications /></Sidebar>} />
        <Route path="/setiting" element={<Sidebar><Setiting /></Sidebar>}
        />
        {/* Booking Routes */}
        {/* Grooming  */}
        <Route path="/bookings/grooming/list" element={ <Sidebar> <GroomingBookingList /> </Sidebar> } />
        <Route path="/bookings/grooming/detail" element={ <Sidebar> <GroomingBookingDetails /> </Sidebar> } />
        {/* Veterinary  */}
        <Route path="/bookings/veterinary/list" element={ <Sidebar> <VeterinaryBookingList /> </Sidebar> } />
        <Route path="/bookings/veterinary/detail" element={ <Sidebar> <VeterinaryBookingDetails /> </Sidebar> } />
        {/* Training  */}
        <Route path="/bookings/training/list" element={ <Sidebar> <TrainingBookingList /> </Sidebar> } /> 
        <Route path="/bookings/training/detail" element={ <Sidebar> <TrainingBookingDetails /> </Sidebar> } />
        {/* Boarding  */}
        <Route path="/bookings/boarding/list" element={ <Sidebar> <BoardingBookingList /> </Sidebar> } />
        <Route path="/bookings/boarding/detail" element={ <Sidebar> <BoardingBookingDetails /> </Sidebar> } />

        {/* Day Care  */}
        <Route path="/bookings/day-care/list" element={ <Sidebar> <DayCareBookingList /> </Sidebar> } />
        <Route path="/bookings/day-care/detail" element={ <Sidebar> <DayCareBookingDetails /> </Sidebar> } />

        {/* End */}
       
      </Routes>
    </div>
  );
};

export default App;
