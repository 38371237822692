import React, { useState, useEffect } from "react";
import "./profile.css";
import ProfileTopNavBar from "./ProfileTopNavBar";
import { IoLocationOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { MdRemoveRedEye } from "react-icons/md";
import {ASSETS_BASE_URL} from '../../config/constants';

function Profile() {
  const store = JSON.parse(sessionStorage.getItem('STORE-INFO'));
  const [storeINFO, SetStoreInfo] = useState(store || {});
  const navigate = useNavigate()
  const [showAccountNo, setShowAccountNo] = useState(false);
  const handleEdit = () => {
    navigate("/storeDetailsForm", { state: {storeINFO}});
  }
 /*********************************************************
  * This function is load to scrolls the window to the top-left corner of the document
  *********************************************************/
 useEffect(()=>{
  window.scrollTo(0,0)
  }, []);
  return (
    <div>
      <ProfileTopNavBar></ProfileTopNavBar>
      <div className="container-fluid">
        <div className="row">
          <div class="col-12">
            <div class="card user_profile_section">
              <div className="user_profile_main_class">
                <div>
                  <img
                    src={storeINFO.image ?  ASSETS_BASE_URL + storeINFO.image : "https://frisbee-llc.s3.me-central-1.amazonaws.com/images/favicon.png"}
                    alt=""
                    className="user_profile_pic"
                    width="80px"
                  />
                </div>
                <div class="card-body">
                  {" "}
                  <span className="profile_user_name" >{storeINFO.shop_name}</span>{" "}
                  <br />
                  <span className="profile_user_admin">{storeINFO.user_type}</span>
                </div>
              </div>
            </div>
            {/* store details */}
            <div className="card profile_store_details">
              <div className="card-body">
                <div className="profile_store_details_for_edit">
                  <div>
                    <span className="orders_now_billing_details_heading">
                      Store Details
                    </span>
                  </div>
                  <div>
                    <button type="button" onClick={handleEdit} class="btn btn-dark">
                      Edit Store
                    </button>
                  </div>
                </div>
                <table className="table table-borderless profile_store_table">
                  <tbody>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <div className="profile_details_section">
                          <IoLocationOutline />{" "}
                          <span className="profile_details_location">
                            Location
                          </span>
                        </div>
                      </td>
                      <td class="text-end order_now_rupees">
                        <span className="profile_store_location">
                          {storeINFO.country} {storeINFO.state}
                        </span>{" "}
                        <br />{" "}
                        <span className="profile_store_location1">
                          {storeINFO.address} {storeINFO.city}
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <IoCallOutline />{" "}
                        <span className="profile_details_location">
                          Contact Number
                        </span>
                      </td>
                      <td class="text-end order_now_rupees">
                        {" "}
                        <span className="profile_store_location">
                          {storeINFO.phone}
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <CiMail />{" "}
                        <span className="profile_details_location">E-Mail</span>
                      </td>
                      <td class="text-end order_now_rupees">
                        {" "}
                        <span className="profile_store_location">
                          {storeINFO.email}
                        </span>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* // Bank Details */}
            {/* <div className="card profile_store_details">
              <div className="card-body">
                <div className="profile_store_details_for_edit">
                  <div>
                    <span className="orders_now_billing_details_heading">
                      Bank Details
                    </span>
                  </div>
                </div>
                <table className="table table-borderless Bank_details_profile_store_table">
                  <tbody>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <div className="profile_details_section">
                          <span className="profile_details_location">
                            Account Holder Name
                          </span>
                        </div>
                      </td>
                      <td class="text-end order_now_rupees">
                        <span className="profile_store_location1">
                           {storeINFO.vendor_card_holder_name}
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <span className="profile_details_location">
                          Account Number
                        </span>
                      </td>
                      <td class="text-end order_now_rupees">
                        <span className="profile_store_location1">
                          {showAccountNo===true?storeINFO.vendor_account_number:'xxxxxxxxxxx'}
                        </span>{" "}
                      </td>
                      <span
                          className="input-group-text my_eye_icon"
                          onClick={()=>setShowAccountNo(!showAccountNo)}
                        >
                          {showAccountNo ? (
                            <i className="bi bi-eye-slash-fill">
                              <MdRemoveRedEye />
                            </i>
                          ) : (
                            <i className="bi bi-eye-fill">
                              <MdRemoveRedEye />
                            </i>
                          )}
                        </span>
                    </tr>
                    <tr>
                      <td colspan="2" className="orders_now_subtotal">
                        <span className="profile_details_location">
                          Account Type
                        </span>
                      </td>
                      <td class="text-end order_now_rupees">
                        <span className="profile_store_location1">
                         {storeINFO.vendor_account_type}
                        </span>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
