import React from "react";
import { Select } from "antd";
import ReactApexChart from "react-apexcharts";

function Recent_order() {
  const series = [
    {
      name: "No. of Orders",
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "area",
    },
    xaxis: {
      categories: [
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
      ],
    },
  };
  return (
    <>
      <div className="inventory_tabs_main_div mt-0">
        <div class="card ">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title mb-0">Financial Analytics</h5>
              <div class="card-title mb-0">
                <Select
                  defaultValue="Filters"
                  className="select_filter_class"
                  style={{
                    width: 120,
                  }}
                  bordered={false}
                  options={[
                    {
                      value: "GMV",
                      label: "GMV",
                    },
                    {
                      value: "Commission accrued",
                      label: "Commission accrued",
                    },
                    {
                      value: "Platform fee",
                      label: "Platform fee",
                    },
                    {
                      value: "Packaging cost",
                      label: "Packaging cost",
                    },
                    {
                      value: "Settlement done",
                      label: "Settlement done",
                    },
                    {
                      value: "Settlement left",
                      label: "Settlement left",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={350}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Recent_order;
