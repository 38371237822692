import React, { useState } from "react";
import "../../BookingDetails.css";
import Top_navbar from "../../../DashBaord/Top_navbar";
import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoCloseCircleOutline } from "react-icons/io5";
import { DatePicker } from "antd";
import BookingFilterDrawer from "./ReScheduleFilter";

import { notification } from "antd";


import moment from "moment";
import { ASSETS_BASE_URL } from "../../../../config/constants";
import { chnageStatus, cancelBooking } from '../../../../controllers/bookings/boardingController';
import { useLocation, useNavigate } from "react-router-dom";

import BookingConformatuinPopUp from "../BookingConformatuinPopUp";
import BookingDeclineConformationPopup from "../BookingDeclineConformationPopup";

const BookingDetails = () => {
  // re-schedule Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const RequesttoggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [bookingData, setBookingData] = useState(state);

  const [order_status, setOrderStatus]=useState("");
  const [popupMessage, setPopupMessage]=useState("");
  
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [declinePopUpOpen, setdeclinePopUpOpen] = useState(false);
  const toggleDeclinePopUp = () => {
    setdeclinePopUpOpen(!declinePopUpOpen);
  };

  const renderOrderButton = (currentStatus, newStatus, buttonText, popupMessage) => {
    return bookingData.status === currentStatus && (
      <button
        type="button"
        className="order_now_heading_button1"
        onClick={() => {
          setPopupMessage(popupMessage);
          setOrderStatus(newStatus);
          togglePopUp();
        }}
      >
        <FaRegCircleCheck /> {buttonText}
      </button>
    );
  };

  const handleOrderStatusChange = async (status='') =>{  
    try {
      let statusError = false;
      let bookingStatus = bookingData.status;
      if(status === 'Accept' && bookingData.status === 'Pending'){
        bookingStatus = 'Accept'
      } else if(status === 'Completed' && bookingData.status === 'Accept'){
        bookingStatus = 'Completed'
      } else {
        statusError = true
      }
      if(statusError === true){
        notification.open({
          message: `Oops!`,
          description: " Your order status not changed .",
          placement: "topRight",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          duration : 2
        });
      } else{
        const options = {
          bookingId : bookingData._id,
          status : bookingStatus
        }
        const res = await chnageStatus(options);
        if(res.status){
          notification.open({
            message: `Booking Status Chnaged`,
            description: " Data Deleted Successfully .",
            placement: "topRight",
            icon: <CheckOutlined style={{ color: "green" }} />,
            duration : 2
          });
          navigate('/bookings/boarding/list');
        } else{
          notification.open({
            message: `Oops!`,
            description: " Your booking status not changed .",
            placement: "topRight",
            icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
            duration : 2
          });
        }
      }
    } catch (error) {
      notification.open({
        message: `Oops!`,
        description: "Server is under maintanance.",
        placement: "topRight",
        icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
        duration : 2
      });
    }
  };

  const handleOrderDecline = async (reason='') =>{  
    try {
      let statusError = false;
      let bookingStatus = bookingData.status;
      if(bookingData.status !== 'Complete'){
        bookingStatus = 'Canceled'
      } else {
        statusError = true
      }
      if(statusError === true){
        notification.open({
          message: `Oops!`,
          description: " Your booking status not changed .",
          placement: "topRight",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          duration : 2
        });
      } else{
        const options = {
          bookingId : bookingData._id,
          reason : reason
        }
        const res = await cancelBooking(options);
        if(res.status){
          notification.open({
            message: `Booking Decline!`,
            description: "Booking Decline Successfully .",
            placement: "topRight",
            icon: <CheckOutlined style={{ color: "green" }} />,
            duration : 2
          });
          navigate('/bookings/boarding/list');
        } else{
          notification.open({
            message: `Oops!`,
            description: "Your booking not decline .",
            placement: "topRight",
            icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
            duration : 2
          });
        }
      }
    } catch (error) {
      notification.open({
        message: `Oops!`,
        description: "Server is under maintanance.",
        placement: "topRight",
        icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
        duration : 2
      });
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div>
          <div className="orders_main_contaniner">
            <div>
              <Top_navbar title={`Booking Details`}></Top_navbar>
            </div>
            <div className="order_now_card_tilte">
              <div class="card">
                <div class="card-body order_now_date_and_time_section">
                  <div>
                    <span className="orders_now_billing_details_heading">
                      Booking Id : {bookingData?.booking_seq_id}
                    </span>
                    <address className="order_now_address_date_and_time">
                      <span>{moment(`${bookingData?.createdAt}`).format( "MMM DD, YYYY, h:m A" )}</span> <br/>
                      <span>Booking Status : {bookingData.status}</span>
                    </address>
                  </div>
                  <div className="order_now_heading_button">
                    {(bookingData.status !== 'Completed' && bookingData.status !== 'Canceled') && (
                    <>
                      <span
                        className="order_now_vendor_re_schedule"
                        onClick={RequesttoggleDrawer}
                        >
                        Re-schedule
                      </span>
                      {/* <button
                        type="button"
                        className="order_now_heading_button2 "
                        onClick={()=>{
                          toggleDeclinePopUp();
                        }}
                      > 
                        <FaRegCircleCheck /> Decline Order
                      </button>  */}
                      {renderOrderButton('Pending', 'Accept', 'Accept Booking', 'Do you want to accept this booking?')}
                      {renderOrderButton('Accept', 'Completed', 'Complete Booking', 'Do you want to complete this booking?')}
                    </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
              {bookingData.status === 'Canceled' && (<>
                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Decline Details
                    </span>
                    <address className="order_now_address">
                        <span className="text-danger">Status : Canceled</span>
                        <span className="text-danger">Reason : {bookingData?.reason}</span>
                        <span className="text-danger">Date & Time : {moment(`${bookingData?.canceled_date}`).format( "MMM DD, YYYY, h:m A" )}</span>
                        <span className="text-danger">By : {bookingData?.canceled_user_type}</span>
                    </address>
                  </div>
                </div>
              </>)}
              {bookingData?.bookingdetails.length > 0 && (
                bookingData?.bookingdetails.map((item)=>(<>
                <div className="card mb-4 ">
                  <div className="card-body">
                    <div class="mb-3 d-flex justify-content-between">
                      <div>
                        <span className="booking_details_billing_details_heading">
                          Services
                        </span>
                      </div>
                    </div>
                    <table class="table table-borderless booking_deatils_table_for_tr">
                      <tbody>
                        {/* <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Category
                          </td>
                          <td class="text-end order_now_rupees">
                            {item?.services?.category?.name}{" "}
                          </td>
                        </tr> */}
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Service
                          </td>
                          <td class="text-end order_now_rupees">
                            {item?.services?.name}{" "}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Amount
                          </td>
                          <td class="text-end order_now_rupees">AED {item?.services?.price}</td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
                </div>
                </>))
              )}
                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Details
                    </span>
                    <address className="order_now_address">
                      <span>Name: {bookingData?.ownerInfo?.name}</span>
                      <span>Phone No. : +971 - {bookingData?.ownerInfo?.phone}</span>
                      <span>Email. : {bookingData?.ownerInfo?.email}</span>
                      <span>Address. : {bookingData?.ownerInfo?.address}</span>
                    </address>
                  </div>
                </div>
                {bookingData?.notes && (
                  <div className="card mb-4">
                    <div className="card-body">
                      <span className="orders_now_billing_details_heading">
                        Notes
                      </span>
                      <address className="order_now_address">
                        <span>{bookingData?.notes}</span>
                      </address>
                    </div>
                  </div>
                )}

              </div>
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Billing Details
                    </span>
                    <table className="table table-borderless orders_now_billing_details_bottom">
                      <tbody>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Subtotal
                          </td>
                          <td class="text-end order_now_rupees">AED {bookingData?.subTotal} </td>
                        </tr>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Discount
                          </td>
                          <td class="text-end order_now_rupees">- AED {bookingData?.discount} </td>
                        </tr>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Extra Charges{" "}
                            <span className="orders_now_convenience_fee">
                              (Convenience Fee)
                            </span>
                          </td>
                          <td class="text-end order_now_rupees">AED 00 </td>
                        </tr>
                        {bookingData?.isCouponUsed == 'Y'? (<>
                          <tr>
                            <td colspan="2" className="orders_now_subtotal">
                              Coupon Discount{" "}
                              <span className="orders_now_convenience_fee">
                                (New200)
                              </span>
                            </td>
                            <td class="text-end order_now_rupees"> - AED 200 </td>
                          </tr>
                        </>):(<></>)}

                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Tax{" "}
                            <span className="orders_now_convenience_fee">
                              ( 5% VAT )
                            </span>
                          </td>
                          <td class="text-end order_now_rupees"> AED {(parseFloat(bookingData?.subTotal) * 5) / 100}</td>
                        </tr>
                        <tr className="order_now_billing_details_border">
                          <td colspan="2" className="orders_now_subtotal">
                            Extra Cost
                          </td>
                          <td class="text-end order_now_rupees"> Rs 0</td>
                        </tr>
                        {/* <hr /> */}
                        <tr class="fw-bold">
                          <td colspan="2" className="order_now_payable_amount">
                            Payable Amount
                          </td>
                          <td class="text-end order_now_payable_amount">
                            AED {bookingData?.subTotal}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Boarding Date & Time
                    </span>
                    <table className="table table-borderless orders_now_billing_details_bottom">
                      <tbody>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            From : {moment(bookingData?.from_date).format('MMM DD, YYYY')}
                          </td>
                          <td class="text-end order_now_rupees">{`Time :${bookingData?.from_time?bookingData.from_time:""}`}</td>
                        </tr>

                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            From : {moment(bookingData?.to_date).format('MMM DD, YYYY')}
                          </td>
                          <td class="text-end order_now_rupees">{`Time : ${bookingData?.from_time?bookingData.to_time:""}`}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Store Details
                    </span>
                    <table className="table table-borderless orders_now_billing_details_bottom">
                      <tbody>
                        <tr>
                          <td class="order_now_rupees">
                            {`Store Name : ${bookingData?.shopId?.shop_name}`}<br/>
                            {`Phone : ${bookingData?.shopId?.phone}`}<br/>
                            {`Email : ${bookingData?.shopId?.email}`}<br/>
                            {`Street : ${bookingData?.shopId?.street}`}<br/>
                            {`Address : ${bookingData?.shopId?.address}`}<br/>
                            {`City : ${bookingData?.shopId?.city}`}<br/>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Branch Details
                    </span>
                    <table className="table table-borderless orders_now_billing_details_bottom">
                      <tbody>
                        <tr>
                          <td class="order_now_rupees">
                            {`Store Name : ${bookingData?.branch?.branch_name}`}<br/>
                            {`Phone : ${bookingData?.branch?.phone}`}<br/>
                            {`Email : ${bookingData?.branch?.email}`}<br/>
                            {`Street : ${bookingData?.branch?.street}`}<br/>
                            {`Address : ${bookingData?.branch?.address}`}<br/>
                            {`City : ${bookingData?.branch?.city}`}<br/>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> */}

              </div>

              {/* ////// */}
            </div>
          </div>
        </div>
      </div>
      <BookingFilterDrawer
        drawerOpen={drawerOpen}
        RequesttoggleDrawer={RequesttoggleDrawer}
        bookingData={bookingData}
      />
      <BookingConformatuinPopUp 
        popUpOpen={popUpOpen} 
        togglePopUp={togglePopUp}
        status={order_status}
        handleOrderChnageStatus={handleOrderStatusChange}
        message = {popupMessage}
      />
      <BookingDeclineConformationPopup 
        popUpOpen={declinePopUpOpen} 
        togglePopUp={toggleDeclinePopUp}
        handleOrderDecline={handleOrderDecline}
      />
    </div>
  );
}

export default BookingDetails;
