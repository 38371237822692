import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import Slide from "@mui/material/Slide";
import "react-calendar/dist/Calendar.css";
import { notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import LoadingEffect from '../../components/Loading/LoadingEffect';
import { Card } from 'antd';
import { deleteNotifications as deleteNotificationAPI } from "../../controllers/notifications/notificationsControllers";

const BookingFilterDrawer = ({ drawerOpen, RequesttoggleDrawer, notificationsList }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    if(notificationsList.length > 0) {
      setIsLoading(false)
    }
  });

  const handleDeleteNotification = async (id) => {
    try {
      const options = {
        url: `notifications/delete-notifications`,
        postData: { id : id }
    }
      const res = await deleteNotificationAPI(options);
      if(res.status === true){
        notification.success({
          message: 'Success!',
          description: 'Deleted successfully.',
          icon: <ExclamationCircleOutlined style={{ color: 'green' }} />,
        });
        RequesttoggleDrawer();
      } else{
        notification.error({
          message: 'Error',
          description: 'There was a problem while deleting  the notification.',
          icon: <ExclamationCircleOutlined style={{ color: '#f5222d' }} />,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'There was a problem while deleting the notification.',
        icon: <ExclamationCircleOutlined style={{ color: '#f5222d' }} />,
      });
    }
  };
  
  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={RequesttoggleDrawer}
      className="reuest_filter_drawer_main_section"
    >
      {/* Select From Date & Time */}
      <Slide direction="left" in={drawerOpen}>
        <div className="myDrawer drawer_for_width_re_schedule">
          <div className="myMainDrawerClass">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Notifications : 
            </Typography>

            <div>
              <IconButton onClick={RequesttoggleDrawer}>
                <IoMdClose />
              </IconButton>
            </div>
          </div>
          {notificationsList?.length > 0 ? (notificationsList.map((items, index)=>(<>
            <Card style={{ marginTop: 16 }}
              type="inner"
              title={`${items.title}`}
              key={index}
              extra={<Link to={false} onClick={()=>handleDeleteNotification(items._id)}>Delete</Link>}
            >
              {items.message}
            </Card>
          </>))) : ''}
        </div>
      </Slide>
      <LoadingEffect isLoading ={isLoading} />
    </Drawer>
  );
};

export default BookingFilterDrawer;

// BY Deafult Open
