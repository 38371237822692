import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";

const OrderConformatuinPopUp = ({ popUpOpen, togglePopUp, handleOrderDecline }) => {
  const handleCancel = () => {
    togglePopUp();
  };
  const [error, setError] =useState('');
  const [reason, setreason] =useState('');
  const handleconform = () => {
    if(reason === ''){
      setError((pre)=>({
        ...pre,
        reason : "reason is required."
      }))
    }else{
      handleOrderDecline(reason);
      handleCancel();
    }
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setreason(value);
  }

  return (
    <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirm
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUp}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p className="all_lable_for_vendor_dashbaord">Please a reason of decline.</p>
          <input type="text" name="reason" id="reason" class="form-control store_input_field_field" placeholder="Enter you reason here..." onChange={handlechange}/>
          {error?.reason?(<p style={{color:'red'}}>{error.reason}</p>):""}
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleconform} style={{ color: "red" }}>
            Yes
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default OrderConformatuinPopUp;
